/**
 * 프로젝트에서 공통으로 사용되는 글로벌 저장 변수
 */

import { createStore } from "vuex";

// F5등.. 새로고침 했을시.. 데이터 손실을 없게 하기위해
// state 데이터를 localstorage에 현행화 하는 plugin
import createPersistedState from "vuex-persistedstate"
import adminInfoStore from "@/store/adminInfoStore";
import prevUrlStore from "@/store/prevUrlStore";
import surveyListDataStore from "@/store/surveyListDataStore";
import newTabParamsStore from "@/store/newTabParamsStore";
import ttsLockStore from "@/store/ttsLockStore";


export default createStore({
  modules:{
    adminInfoStore : adminInfoStore,
    prevUrlStore : prevUrlStore,
    surveyListDataStore : surveyListDataStore,
    newTabParamsStore : newTabParamsStore,
    ttsLockStore : ttsLockStore


  },
  // store 내용을 localstorage 와 sync 맞춤
  plugins : [ createPersistedState({
    key: 'adminInfoKey',
    paths: ['adminInfoStore.adminInfo', 'adminInfoStore.isLogged', 'surveyListDataStore.surveyDataList'
      ,'surveyListDataStore.fileDataList','newTabParamsStore.params','ttsLockStore.isProcessingTTS']
  }) ]
})
