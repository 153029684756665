const ttsLockStore = {
  state: {
    isProcessingTTS: false
  },
  getters: {
    getIsProcessingTTS(state) {
      return state.isProcessingTTS;
    }
  },
  mutations: {
    setIsProcessingTTS(state, param) {
      state.isProcessingTTS = param;
    },
  },
  actions: {
    updateIsProcessingTTS({ commit }, param) {
      commit('setIsProcessingTTS', param);
    }
  }
};

export default ttsLockStore;
