/**
 * api 공통
 */
import NetworkService from "@/api/net.service";

const NetApiServer = new NetworkService("")

/**
 * 로그인/인증 부분 api
 * @type {{getSidoList(): Promise<AxiosResponse<any>>}}
 */
export const ApiAuth = {

  /**
   * 로그인.
   * @returns {Promise<AxiosResponse<any>>}
   */
  getLogIn (params) {
    return NetApiServer.post("/api/auth/login", params).then(res => {
      return res
    })
  },

  /**
   * email 인증메일 발송
   * @param params
   */
  emailCheck (params) {
    return NetApiServer.post("/api/email/send", params).then(res => {
      return res
    })
  },
  /**
   * email 인증번호 확인
   * @param params
   * @returns {Promise<AxiosResponse<any>>}
   */
  emailConfirm (params) {
    return NetApiServer.post("/api/email/confirm", params).then(res => {
      return res;
    })
  },

  /**
   * 가입 등록
   * @param params
   * @returns {Promise<AxiosResponse<any>>}
   */
  userJoin (formData) {
    return NetApiServer.multipartFormData("/api/auth/join", formData).then(res => {
      return res
    })
  },
  reAgree (params) {
    return NetApiServer.get("/api/auth/reAgree", params).then(res => {
      return res
    })
  },
  /**
   * email 내 정보 검색
   * @param params
   */
  getMyInfo (params) {
    return NetApiServer.post("/api/auth/getMyInfo", params).then(res => {
      return res
    })
  },
  /**
   * admin_info 테이블 ADM_ID로 row 가져오기(조회용)
   * @param params
   */
  getDoctorInfo (params) {
    return NetApiServer.post("/api/auth/getDoctorInfo", params).then(res => {
      return res
    })
  },
  /**
   * 내 정보 수정
   * @param params
   */
  modify (formData) {
    return NetApiServer.multipartFormData("/api/auth/modify", formData).then(res => {
      return res
    })
  },
  /**
   * 내 정보 삭제
   * @param params
   */
  myInfoDel (params) {
    return NetApiServer.post("/api/auth/delete", params).then(res => {
      return res
    })
  },
  /**
   * 아이디 찾기
   * @param params
   */
  findId (params) {
    return NetApiServer.post("/api/auth/find/id", params).then(res => {
      return res
    })
  },
  /**
   * 비밀번호 찾기
   * @param params
   */
  findPw (params) {
    return NetApiServer.post("/api/auth/find/pw", params).then(res => {
      return res
    })
  },

  /**
   * 승인/미승인 처리
   * @param params
   */
  updAcceptYn (params) {
    return NetApiServer.post("/api/auth/updAcceptYn", params).then(res => {
      return res
    })
  },

  /**
   * 회원 삭제
   * @param params
   */
  delDoctor (params) {
    return NetApiServer.post("/api/auth/delDoctor", params).then(res => {
      return res
    })
  },

  /**
   * 콘텐츠 프리셋 등록한 관리자 목록
   * @param params
   * @returns {*}
   */
  admList(params) {
    return NetApiServer.post("/api/auth/adminList", params).then(res=>{
      return res
    })
  }
}
