<script setup>
import { useRoute } from 'vue-router';
/* import {Common} from '@/utils/common' */
import {computed, ref} from "vue";
import {useStore} from "vuex";

const store = useStore()
/* const LogOut = (() => {
  Common.LogOut()
}) */

// 로그인 admin 정보
const adminInfo = computed(() => store.getters.getAdminInfo)

const route = useRoute();


const SurveyMngHover = ref(false)
const ContentsMngHover = ref(false)
const SupportMngHover = ref(false)
const isActiveEqual = (path) => {
  // 현재 라우트에 path 문자열이 일치하면 true
  return route.path === (path);
};
const isActiveIncludes = (path) => {
  // 현재 라우트에 path 문자열을 포함하면 true
  return route.path.includes(path);
};
</script>

<template>
  <!-- header -->
  <div class="header">
    <h1 style="width: 20rem;"><router-link to="/">WECARE</router-link></h1>
    <ul class="nav">

      <li :class="isActiveEqual('/DoctorMng') || isActiveEqual('/') ? 'on' : ''" v-show="adminInfo.admAuth === 'SUPER_ADMIN' || adminInfo.admAuth === 'HOSP_ADMIN'">
        <router-link to="/DoctorMng"><span>의료진 관리</span></router-link>
      </li>

      <li :class="isActiveIncludes('/PatientMng') ? 'on' : ''">
        <router-link to="/PatientMng"><span>환자 설문</span></router-link>
      </li>

      <li :class="isActiveIncludes('/SurveyMng') || isActiveEqual('/ResearchMng') ? 'on' : ''" @mouseover="SurveyMngHover=true" @mouseout="SurveyMngHover=false">
        <router-link to="/SurveyMng"><span>설문 관리+</span></router-link>

        <ul class="sub_menu menu01" :class="SurveyMngHover == true ? 'active' : ''">
          <li :class="isActiveEqual('/SurveyMng') ? 'on' : ''"><router-link to="/SurveyMng"><span>개별 설문 관리</span></router-link></li>
          <li :class="isActiveEqual('/SurveyMngGrp') ? 'on' : ''"><router-link to="/SurveyMngGrp"><span>그룹 설문 관리</span></router-link></li>
          <li :class="isActiveEqual('/SurveyMngRes') ? 'on' : ''"><router-link to="/SurveyMngRes"><span>연구 설문 관리</span></router-link></li>
          <li v-show="adminInfo.admAuth === 'SUPER_ADMIN' || adminInfo.admAuth === 'HOST_ADMIN'" :class="isActiveEqual('/ResearchMng') ? 'on' : ''"><router-link to="/ResearchMng"><span>연구 관리</span></router-link></li>
        </ul>
      </li>
<!--      <li :class="isActiveIncludes('/SurveyPerform') ? 'on' : ''">-->
<!--        <router-link to="/SurveyPerformToday"><span>설문 수행</span></router-link>-->
<!--      </li>-->

      <li :class="isActiveEqual('/SurveyResult') ? 'on' : ''">
        <router-link to="/SurveyResult"><span>설문 결과</span></router-link>
      </li>

<!--      <li :class="isActiveIncludes('/Send') ? 'on' : ''">
        <router-link to="/SendingStatus"><span>알림 관리</span></router-link>
      </li>-->

<!--      <li><a href="KOQUSS통계.html" title="KOQUSS 통계"><span>KOQUSS 통계</span></a></li>-->


      <li :class="isActiveIncludes('/ContentsMng') || isActiveIncludes('/ContentsPresetMng') ? 'on' : ''" @mouseover="ContentsMngHover=true" @mouseout="ContentsMngHover=false">
        <router-link to="/ContentsMng"><span>콘텐츠 관리+</span></router-link>
        <ul class="sub_menu menu02" :class="ContentsMngHover == true ? 'active' : ''">
          <li :class="isActiveIncludes('/ContentsMng') ? 'on' : ''"><router-link to="/ContentsMng"><span>콘텐츠 관리</span></router-link></li>
          <li :class="isActiveIncludes('/ContentsPresetMng') ? 'on' : ''"><router-link to="/ContentsPresetMng"><span>콘텐츠 프리셋 관리</span></router-link></li>
        </ul>
      </li>

      <li :class="isActiveEqual('/QnABoard') || isActiveIncludes('/FAQMng') || isActiveEqual('/ShoppingHelpService') ? 'on' : ''" @mouseover="SupportMngHover = true" @mouseout="SupportMngHover = false">
        <router-link to="/QnABoard"><span>환자지원 관리+</span></router-link>
        <ul class="sub_menu menu03" :class="SupportMngHover === true ? 'active' : ''">
          <li :class="isActiveEqual('/QnABoard') ? 'on' : ''"><router-link to="/QnABoard"><span>문의 관리</span></router-link></li>
          <li :class="isActiveIncludes('/FAQMng') ? 'on' : ''"><router-link to="/FAQMng"><span>FAQ 관리</span></router-link></li>
          <li :class="isActiveEqual('/ShoppingHelpService') ? 'on' : ''"><router-link to="/ShoppingHelpService"><span>쇼핑‧도움서비스</span></router-link></li>
        </ul>
      </li>

      <li :class="isActiveIncludes('/Board') ? 'on' : ''">
        <router-link to="/BoardMng"><span>게시판</span></router-link>
      </li>

    </ul>
    <ul class="top_links">
      <li>
        <router-link to="/MyInfoMng" class="btn_modify"><span>내정보 관리</span></router-link>
      </li>
      <li>
        <router-link to="/LogOut" class="btn_logout"><span>내정보 관리</span></router-link>
      </li>
    </ul>
  </div>
  <!-- //header -->
</template>

<style scoped>

</style>
